<template>
  <client-only>
    <div class="tw-text-center">
      <FontAwesomeIcon v-if="badge === 0" icon="fa-star fa-regular" class="tw-text-xs" />

      <template v-else>
        <FontAwesomeIcon v-for="i in badge" :key="i" icon="star" class="tw-text-xs" />
      </template>
    </div>
  </client-only>
</template>

<script setup lang="ts">
const props = defineProps<{
  id?: number
}>()

let user = useUser(props.id)

const badge = computed(() => user.value?.badge ?? 0)
</script>
